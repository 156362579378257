import { CreatorFilter } from "../enum/creator-filter.enum";

export const creatorFilter = [{
    name : CreatorFilter.Pending, 
    translate: 'PENDING',
    quantity: 0
},{
    name : CreatorFilter.Confirmed, 
    translate: 'CONFIRMED',
    quantity: 0
},{
    name : CreatorFilter.Invited,
    translate: 'INVITED',
    quantity: 0
},{
    name : CreatorFilter.Suggested,
    translate: 'SUGGESTED',
    quantity: 0
},{
    name : CreatorFilter.Rejected,
    translate: 'CANCELED',
    quantity: 0
},{
    name : CreatorFilter.Negotiation, 
    translate: 'NEGOTIATION',
    quantity: 0
},{
    name : CreatorFilter.NotAvailable,
    translate: 'NOTAVAILABLE',
    quantity: 0
}];

