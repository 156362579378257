import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CreatorApproval } from '../../../models/CreatorApproval.model';

const DefaultCreatorIcon = '../../../../assets/icons/svg/icons/all_creators.svg';

@Component({
  selector: 'x-creator-select',
  templateUrl: './creator-select.component.html',
  styleUrls: ['./creator-select.component.css']
})


export class CreatorSelectComponent implements OnInit {
  
  @Input() public creators: CreatorApproval[];
  @Output() public selectedChanges: EventEmitter<CreatorApproval> = new EventEmitter<CreatorApproval>(undefined);

  public creatorForm: FormControl;
  public ts: any;
  public selectedCreatorName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public selectedCreatorIconImg: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
 
  constructor(public translateService: TranslateService) {
    
  }

  public ngOnInit(): void {
    this.translateService.getTranslation(this.translateService.currentLang).subscribe((translations) => {
      this.ts = translations;
    }
    );
    this.creatorForm = new FormControl("All Creators");
    this.selectedCreatorIconImg.next(this.selectedImgSource());
    this.selectedCreatorName.next(this.selectedText());
    this.creatorForm.valueChanges
      .subscribe({
        next:() => {
          this.selectedCreatorIconImg.next(this.selectedImgSource());
          this.selectedCreatorName.next(this.selectedText());
        }, error: (error: Error) => {
          console.log(error);
        }
      })
  }

  public onSelectionChanged($event){
    this.selectedChanges.emit($event.value);
  }

  public selectedImgSource(): string {
    return this.creatorForm.value === "All Creators" ?  DefaultCreatorIcon : this.creatorForm.value.getProfilePicture();
  }

  public selectedText(): string {
    return this.creatorForm.value === "All Creators" ? this.creatorForm.value : this.creatorForm.value.getSocialName();
  }

}
