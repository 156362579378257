import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { CampaignService } from '../../../services/campaign.service';
import { Observable, Subscription } from 'rxjs';
import { Campaign } from '../../../models/campaign.model';
import { Alert } from '../../../models/interfaces';
import { CreatorApproval } from '../../../models/CreatorApproval.model';
import { CreatorService } from '../../../services/creator.service';
import { SubmissionService } from '../../../services/submission.service';
import { PerformanceSubmission, Submission } from '../../../models/submission.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { NONE_TYPE } from '@angular/compiler';
import { MatMenuTrigger } from '@angular/material/menu';
import { Privilege } from '../../../models/role.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PrivilegeBlockModalComponent } from '../../shared/privilege-block-modal/privilege-block-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ShareComponent } from './share/share.component';
import { CloneCampaignComponent } from './clone-campaign/clone-campaign.component';
import { CampaignCommercialProposalComponent } from './campaign-commercial-proposal/campaign-commercial-proposal.component';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { environment } from 'src/environments/environment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CampaignCommercialProposal2Component } from './campaign-commercial-proposal2/campaign-commercial-proposal2.component';
import { CampaignPresentation } from 'src/app/models/campaignPresentation.model';
import { CampaignCommercialProposal3Component } from './campaign-commercial-proposal3/campaign-commercial-proposal3.component';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {

  campaignSub: Subscription;
  creatorsApprovalSub: Subscription;
  routeSub: Subscription;
  submissionsSub: Subscription;
  campaign: Campaign;
  campaign$: Observable<Campaign>;
  creatorsApproval: Array<CreatorApproval>;
  creatorsApproval$: Observable<Array<CreatorApproval>>;
  submissions: Array<Submission>;
  submissionsPerformanceStats: Array<PerformanceSubmission>;
  alert: Alert;
  isAdmin = this.userService.isAdmin();
  view = new Set<string>([environment['campaign_view'] ?? 'creators']);
  modalRefDelete: NgbModalRef;
  modalRef: NgbModalRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  someMethod() {
    this.trigger.openMenu();
  }

  constructor(private route: ActivatedRoute, private cs: CampaignService,
              private router: Router, private creatorService: CreatorService,private modalService: NgbModal, private readonly dialog: MatDialog,
              private submissionService: SubmissionService, private userService: UserService) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      if (!!params['id'] && !Number.isNaN(Number.parseInt(params['id'], 10))) {
        this.initializeSubs(params['id']);
        if(params['tab']) {
          this.view  = new Set<string>([params['tab']]);
        }
      } else {
        this.alert = { message: 'Invalid campaign, please check again', type: 'danger' };
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.campaignSub) {
      this.campaignSub.unsubscribe();
    }
    if (this.creatorsApprovalSub) {
      this.creatorsApprovalSub.unsubscribe();
    }
    if (this.submissionsSub) {
      this.submissionsSub.unsubscribe();
    }
  }

  allCreatorPlannerContents() : CreatorPlannerContent[] {
    const result:CreatorPlannerContent[] = [];
    for(const creatorApproval of this.creatorsApproval) {
      if (!creatorApproval.planner) continue;
      for(const planner of creatorApproval.planner) {
        if (!planner.plannerContents) continue;
        for(const content of planner.plannerContents) {
          result.push(content);
        }
      }
    }
    return result
      .sort((a, b) => a.postAt > b.postAt ? 1 : -1);
  }

  deleteCampaign(): void {
    this.delete();
    this.modalRefDelete.close();
  }

  openDelete(deleteConf: TemplateRef<CampaignComponent>): void {
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CAMPAIGNS], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
      this.modalRefDelete = this.modalService.open(deleteConf);
    } else {
      this.modalRefDelete = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }

  delete(): void {
    this.cs.delete(this.campaign).subscribe( async () => {
      await this.router.navigateByUrl('dashboard');
    }, () => {
      window.scroll(0, 0);
      this.alert = {
        type: 'danger',
        message: 'An error occurred, please try again later.'
      };
    });
  }

  openShareModal(){
    this.dialog.open(ShareComponent, {
      width: '656px', height: '900px',
      data:{
        campaign: this.campaign
      }
    });
  }

  openCloneModal(){
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CAMPAIGNS], this.campaign.companyId, this.campaign.brandId)) {
      this.dialog.open(CloneCampaignComponent, {
        width: '656px',
        data:{
          campaign: this.campaign
        }
      });
    } else {
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }

  async editCampaign() {
    if (this.userService.hasPrivileges(
      [Privilege.PREDEFINED.EDIT_CAMPAIGNS], this.campaign.companyId, this.campaign.brandId, this.campaign.id)) {
      await this.router.navigateByUrl('campaign/edit/' + this.campaign.id);
    } else {
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }

  // openCommercialProposal() {
  //   this.modalRef = this.modalService.open(CampaignCommercialProposalComponent, { windowClass: 'app-campaign-commercial-proposal'});
  //   this.modalRef.componentInstance.campaign = this.campaign;
  //   this.modalRef.componentInstance.creators = this.creatorsApproval;
  //   this.modalRef.componentInstance.creatorsSub = this.creatorsApprovalSub;
  // }

  openCommercialProposal() {
    this.modalRef = this.modalService.open(CampaignCommercialProposal3Component, { 
      windowClass: 'app-campaign-commercial-proposal3', 
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.componentInstance.campaign = this.campaign;
  }

  private initializeSubs(campaignId: number): void {
    this.campaign$ = this.cs.getCampaign(campaignId);
    this.campaignSub = this.campaign$.subscribe( x => {
      this.campaign = x;
      this.submissionsSub = this.submissionService.getSubmissions(this.campaign)
          .subscribe( response => {
            this.submissions  =response.submissions;
            this.submissionsPerformanceStats = response.performanceStats;
            this.submissions.forEach(s => s.campaign = this.campaign);
            this.submissionsPerformanceStats.forEach(s => s.campaign = this.campaign);
            this.infoLoaded();
          });
    });
    this.creatorsApproval$ = this.campaign$.pipe(
      switchMap(campaign =>
        this.creatorService.getCampaignCreators(campaign.companyId, campaign.brandId, campaignId).pipe(
          tap(creators => creators.forEach(c => c.campaign = campaign))
        )
      )
    );
    this.creatorsApprovalSub = this.creatorsApproval$
      .subscribe(x => {
        this.creatorsApproval = x;
        this.infoLoaded();
      } );
  }

  private infoLoaded() {
    if (!!this.creatorsApproval && !!this.submissions) {
      this.creatorsApproval.forEach( x => {
        this.submissions.forEach( y => {
          if ( y.userId === x.userId ) {
            x.submissions.push(y);
          }
        });
        this.submissionsPerformanceStats.forEach( y => {
          if ( y.userId === x.userId ) {
            x.submissionsPerformanceStats.push(y);
          }
        });
      });
    }
  }
}
