import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CreatorApproval,
  CreatorApprovalPlanner,
} from '../../../../models/CreatorApproval.model';
import { Campaign } from '../../../../models/campaign.model';
import { CreatorService } from '../../../../services/creator.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { AlertService } from '../../../../services/alert.service';
import { socialNetworksArray } from '../../../../models/SocialNetworksArray';
import { TranslateService } from '@ngx-translate/core';
import { Submission } from 'src/app/models/submission.model';
import { Privilege } from 'src/app/models/role.model';
import { PrivilegeBlockModalComponent } from 'src/app/modules/shared/privilege-block-modal/privilege-block-modal.component';
import { Router } from '@angular/router';
import { MatChip } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { CreatorFilter } from '../../../../enum/creator-filter.enum';
import { creatorFilter } from '../../../../models/creatorFilterArray';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerComponent } from 'src/app/modules/shared/spinner/spinner.component';
import { Helper } from '../../../../support/helper.support';
import { finalize, tap } from 'rxjs/operators';
import { InstagramAudience } from '../../../../models/instagram.model';
import { TikTokAudience } from '../../../../models/tiktok.model';
import { YoutubeAudience } from '../../../../models/youtube.model';
import { Audience } from '../../../../models/Interfaces/Audience';
import { CampaignService } from '../../../../services/campaign.service';
import { Platform } from '../../../../enum/platforms-string.enum';
import { CalculatorPlanner } from 'src/app/models/calculatorPlanner';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { CreatorPlannerContent } from 'src/app/models/CreatorPlannerContent.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-creators',
  templateUrl: './campaign-creators.component.html',
  styleUrls: ['./campaign-creators.component.scss'],
})
export class CampaignCreatorsComponent implements OnChanges, OnDestroy {
  @ViewChild(SpinnerComponent) spinner: SpinnerComponent;
  @Input() creatorsApprovals: Array<CreatorApproval>;
  @Input() submissions: Submission[];
  @Input() campaign: Campaign;
  public confirmed: Array<CreatorApproval>;
  public invited: Array<CreatorApproval>;
  public rejected: Array<CreatorApproval>;
  public notAvailable: Array<CreatorApproval>;
  public pending: Array<CreatorApproval>;
  public suggested: Array<CreatorApproval>;
  public negotiation: Array<CreatorApproval>;
  public url: string;
  public openSlider: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public modalRef: NgbModalRef;
  public activeFilters = new Set<string>(['All']);
  public sub: Subscription;
  public username: string;
  public filters: Array<any>;
  private usernameError = false;
  public socialNetworks = socialNetworksArray;
  public socialNetwork = null;
  public posting = false;
  public isAdmin = false;
  public translations: any;
  public remainingBudget: number;
  public shareFilters = new Set<string>();
  public budgetView = environment['campaign_budgetView'] ?? false;
  public chipControl = new FormControl(new Set());

  calculator: CalculatorPlanner;

  public checkedSuggested: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private cs: CreatorService,
    private modalService: NgbModal,
    public us: UserService,
    private creatorService: CreatorService,
    private readonly campaignService: CampaignService,
    private as: AlertService,
    private ts: TranslateService,
    private userService: UserService,
    private router: Router
  ) {
    this.filters = creatorFilter;
  }

  public ngOnChanges(): void {
    this.ts.getTranslation(this.ts.currentLang).subscribe((translations) => {
      this.translations = translations;
    });
    this.isAdmin = this.us.isAdmin();
    this.categorizeApprovals();
    this.showOrHideSlider();
    this.calculate();
    this.url =
      window.location.href.split('/').slice(0, 3).join('/') +
      '/campaignCreators/'; // magic
  }

  get suggestedSelected() {
    return this.suggested.filter(creatorApproval => creatorApproval.calc_selected);
  }

  calculate() {
    const getPlannersForCampaignConcat = (prev, creatorApproval: CreatorApproval) =>
      prev.concat(creatorApproval.getPlannersForCampaign());
    const plannersConfirmed = this.confirmed.reduce(getPlannersForCampaignConcat , []);
    const plannersSuggested = this.suggestedSelected.reduce(getPlannersForCampaignConcat, []);
    const calcConfirmed  = new CalculatorPlanner('confirmed', plannersConfirmed);
    const calcSuggested  = new CalculatorPlanner('suggested', plannersSuggested);
    this.calculator = CalculatorPlanner.join('total',  [calcConfirmed, calcSuggested]);
  }

  public slider($event): void {
    const creator = $event.creator as CreatorApproval;
    creator.calc_selected = !$event.deleted;
    this.spinner.spin();
    this.campaignService.setCampaignSelectedCreator(
        this.campaign.companyId,
        this.campaign.brandId,
        this.campaign.id,
        $event.creator.id,
        !$event.deleted
      )
      .pipe(finalize(() => {
        this.spinner.stop();
      }))
      .subscribe({
        error: (error) => {
          console.log(error);
        },
      });
    this.calculate();
    this.showOrHideSlider();
  }

  showOrHideSlider() {
    const open = this.suggestedSelected.length > 0 || this.confirmed.length >= 1;
    this.openSlider.next(open);
  }

  public creatorTable() {
    this.budgetView = !this.budgetView;
  }

  public categorizeApprovals(): void {
    this.confirmed = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Confirmed
    );
    this.invited = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Invited
    );
    this.rejected = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Rejected
    );
    this.notAvailable = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.NotAvailable
    );
    this.pending = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Pending
    );
    this.suggested = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Suggested
    );
    this.negotiation = this.creatorsApprovals.filter(
      (x) => x.status === CreatorFilter.Negotiation
    );

    this.filters.forEach((filter) => {
      switch (filter.name) {
        case CreatorFilter.Confirmed:
          filter.quantity = this.confirmed.length;
          break;
        case CreatorFilter.Invited:
          filter.quantity = this.invited.length;
          break;
        case CreatorFilter.Rejected:
          filter.quantity = this.rejected.length;
          break;
        case CreatorFilter.NotAvailable:
          filter.quantity = this.notAvailable.length;
          break;
        case CreatorFilter.Pending:
          filter.quantity = this.pending.length;
          break;
        case CreatorFilter.Suggested:
          filter.quantity = this.suggested.length;
          break;
        case CreatorFilter.Negotiation:
          filter.quantity = this.negotiation.length;
          break;
      }
    });
  }

  public isCreator(
    c: CreatorApproval | { creator: CreatorApproval; reason: string }
  ): c is CreatorApproval {
    return (
      (c as { creator: CreatorApproval; reason: string }).reason === undefined
    );
  }

  public changeStatus(
    event: CreatorApproval | { creator: CreatorApproval; reason: string },
    newStatus: string
  ): void {
    this.spinner.spin();
    const creator = !this.isCreator(event) ? event.creator : event;
    const reason = !this.isCreator(event) ? event.reason : '';
    const oldStatus = creator.status;
    // creator.status = 'Transition';
    // this.categorizeApprovals();
    this.sub = this.cs
      .changeStatus(
        this.campaign.companyId,
        this.campaign.brandId,
        this.campaign.id,
        creator.userId,
        newStatus,
        reason
      )
      .subscribe(
        () => {
          if (newStatus === 'Rejected') {
            newStatus = 'Canceled';
          }
          creator.status = newStatus;
          this.categorizeApprovals();
          this.spinner.stop();
        },
        () => {
          creator.status = oldStatus;
          this.categorizeApprovals();
          this.spinner.stop();
        }
      );
  }

  public filterByStatus(status: string, chip: MatChip): void {
    chip.toggleSelected();

    if (this.activeFilters.has(status)) {
      this.activeFilters.delete(status);
      if (this.activeFilters.size === 0) {
        this.activeFilters.add('All');
      }
    } else {
      if (status === 'All') {
        this.activeFilters.clear();
      } else {
        this.activeFilters.delete('All');
      }
      this.activeFilters.add(status);
    }
  }

  public selectShareFilter(status: string, chip: MatChip): void {
    chip.toggleSelected();
    if (status === 'All') {
      if (this.shareFilters.size === 6) {
        this.shareFilters.clear();
      } else {
        this.shareFilters = new Set([
          CreatorFilter.Suggested,
          CreatorFilter.Confirmed,
          CreatorFilter.Pending,
          CreatorFilter.Invited,
          CreatorFilter.Negotiation,
          CreatorFilter.NotAvailable,
        ]);
      }
    } else if (this.shareFilters.has(status)) {
      this.shareFilters.delete(status);
    } else {
      this.shareFilters.add(status);
    }
  }

  shareCampaignCreators(): void {
    let shareUrl = this.url + this.campaign.publicToken.trim();
    if (this.shareFilters.size > 0 && this.shareFilters.size < 6) {
      shareUrl += `?filter=${Array.from(this.shareFilters).join(',')}`;
    }
    this.copyMessage(shareUrl);
  }

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  openInviteModal(content: TemplateRef<CampaignCreatorsComponent>) {
    if (
      this.userService.hasPrivileges(
        [Privilege.PREDEFINED.EDIT_PROFILES],
        this.campaign.companyId,
        this.campaign.brandId,
        this.campaign.id
      )
    ) {
      this.modalRef = this.modalService.open(content, {
        windowClass: 'app-submission-details-modal',
      });
    } else {
      this.modalRef = this.modalService.open(PrivilegeBlockModalComponent);
    }
  }
  openShareModal(content: TemplateRef<CampaignCreatorsComponent>): void {
    this.modalRef = this.modalService.open(content, {
      windowClass: 'app-submission-details-modal',
    });
  }

  inviteCreatorFunc(): void {
    this.usernameError = !this.username || this.username.length === 0;
    if (
      this.usernameError ||
      !this.socialNetwork ||
      this.socialNetwork.length === 0
    ) {
      return;
    }
    this.modalRef.close();
    this.spinner.spin();
    this.sub = this.cs
      .inviteCreator(
        this.username,
        this.campaign.companyId,
        this.campaign.brandId,
        this.campaign.id,
        this.socialNetwork
      )
      .subscribe(
        async (creator) => {
          this.as.emitSuccess(this.username + ' successfully invited!');
          const creatorApprovals = await this.creatorService.getCampaignCreators(
            this.campaign.companyId, this.campaign.brandId, this.campaign.id).pipe(
            tap(creators => creators.forEach(c => c.campaign = this.campaign))
          ).toPromise();
          await this.creatorService.updateAllSocialNetworks(creator.user.id).toPromise();
          this.creatorsApprovals = creatorApprovals;
          // const approval = new CreatorApproval().deserialize(response);
          // this.creatorsApprovals.push(approval);
          this.categorizeApprovals();
          this.sub.unsubscribe();
          this.sub = null;
          this.modalRef.close();
          this.spinner.stop();
        },
        (error) => {
          this.as.emitError(error);
          this.sub.unsubscribe();
          this.sub = null;
          this.modalRef.close();
          this.spinner.stop();
        }
      );
  }

  removeCreatorFunc(creator: CreatorApproval): void {
    creator.status = 'Removed';
    this.categorizeApprovals();
    this.as.emitSuccess(creator.name + ' successfully removed!');
  }

  copyMessage(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.as.emitSuccess(this.ts.instant('CAMPAIGN.SHARE.LINK.COPIED'));
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  searchCreators() {
    this.router.navigateByUrl(`creator?campaign=${this.campaign.id}`);
  }

  saveCreator() {
    this.creatorService
      .getCampaignCreators(
        this.campaign.companyId,
        this.campaign.brandId,
        this.campaign.id
      )
      .pipe(finalize(() => this.creatorService.clearCaches()))
      .subscribe({
        next: (data: CreatorApproval[]) => {
          this.creatorsApprovals = data;
          this.categorizeApprovals();
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
        },
      });
  }

  public confirmSelecteds(): void {
    this.suggested
      .forEach(
        (creator) => {
          if(creator.calc_selected) {
            this.changeStatus(creator, 'Confirmed');
          }
      });
  }
}
